table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0; }

table.dataTable td,
table.dataTable th {
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center; }

table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap; }

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap; }

div.dataTables_wrapper div.dataTables_length select {
  width: auto;
  display: inline-block; }

div.dataTables_wrapper div.dataTables_filter {
  text-align: right; }

div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left; }

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto; }

div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em;
  white-space: nowrap; }

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right; }

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0; }

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px; }

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none; }

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative; }

table.dataTable thead .sorting:before, table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3; }

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\2191"; }

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\2193"; }

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1; }

table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0; }

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important; }

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

div.dataTables_scrollBody table thead .sorting:before,
div.dataTables_scrollBody table thead .sorting_asc:before,
div.dataTables_scrollBody table thead .sorting_desc:before,
div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none; }

div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none; }

div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
  margin-top: 0 !important;
  border-top: none; }

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center; } }

table.dataTable.table-sm > thead > tr > th {
  padding-right: 20px; }

table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em; }

table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
  top: 5px; }

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0; }

table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 0; }

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0; }

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0; }

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0; }

div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
  padding-left: 0; }

div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
  padding-right: 0; }

@keyframes dtb-spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes dtb-spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: white;
  border: 2px solid #111;
  -webkit-box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  text-align: center;
  z-index: 21; }

div.dt-button-info h2 {
  padding: 0.5em;
  margin: 0;
  font-weight: normal;
  border-bottom: 1px solid #ddd;
  background-color: #f3f3f3; }

div.dt-button-info > div {
  padding: 1em; }

div.dt-button-collection-title {
  text-align: center;
  padding: 0.3em 0 0.5em;
  font-size: 0.9em; }

div.dt-button-collection-title:empty {
  display: none; }

div.dt-button-collection {
  position: absolute;
  z-index: 2001; }

div.dt-button-collection div.dropdown-menu {
  display: block;
  z-index: 2002;
  min-width: 100%; }

div.dt-button-collection div.dt-button-collection-title {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15); }

div.dt-button-collection.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  border-radius: 0; }

div.dt-button-collection.fixed.two-column {
  margin-left: -200px; }

div.dt-button-collection.fixed.three-column {
  margin-left: -225px; }

div.dt-button-collection.fixed.four-column {
  margin-left: -300px; }

div.dt-button-collection > :last-child {
  display: block !important;
  -webkit-column-gap: 8px;
  -moz-column-gap: 8px;
  -ms-column-gap: 8px;
  -o-column-gap: 8px;
  column-gap: 8px; }

div.dt-button-collection > :last-child > * {
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  break-inside: avoid; }

div.dt-button-collection.two-column {
  width: 400px; }

div.dt-button-collection.two-column > :last-child {
  padding-bottom: 1px;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  -ms-column-count: 2;
  -o-column-count: 2;
  column-count: 2; }

div.dt-button-collection.three-column {
  width: 450px; }

div.dt-button-collection.three-column > :last-child {
  padding-bottom: 1px;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  -ms-column-count: 3;
  -o-column-count: 3;
  column-count: 3; }

div.dt-button-collection.four-column {
  width: 600px; }

div.dt-button-collection.four-column > :last-child {
  padding-bottom: 1px;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  -ms-column-count: 4;
  -o-column-count: 4;
  column-count: 4; }

div.dt-button-collection .dt-button {
  border-radius: 0; }

div.dt-button-collection.fixed {
  max-width: none; }

div.dt-button-collection.fixed:before, div.dt-button-collection.fixed:after {
  display: none; }

div.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999; }

@media screen and (max-width: 767px) {
  div.dt-buttons {
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 0.5em; }
  div.dt-buttons a.btn {
    float: none; } }

div.dt-buttons button.btn.processing, div.dt-buttons div.btn.processing, div.dt-buttons a.btn.processing {
  color: rgba(0, 0, 0, 0.2); }

div.dt-buttons button.btn.processing:after, div.dt-buttons div.btn.processing:after, div.dt-buttons a.btn.processing:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  content: ' ';
  border: 2px solid #282828;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: dtb-spinner 1500ms infinite linear;
  -o-animation: dtb-spinner 1500ms infinite linear;
  -ms-animation: dtb-spinner 1500ms infinite linear;
  -webkit-animation: dtb-spinner 1500ms infinite linear;
  -moz-animation: dtb-spinner 1500ms infinite linear; }

div.dt-autofill-handle {
  position: absolute;
  height: 8px;
  width: 8px;
  z-index: 102;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #0275d8;
  cursor: pointer; }

div.dtk-focus-alt div.dt-autofill-handle {
  background: #ff8b33; }

div.dt-autofill-select {
  position: absolute;
  z-index: 1001;
  background-color: #0275d8;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255, 255, 255, 0.5) 5px, rgba(255, 255, 255, 0.5) 10px); }

div.dt-autofill-select.top, div.dt-autofill-select.bottom {
  height: 3px;
  margin-top: -1px; }

div.dt-autofill-select.left, div.dt-autofill-select.right {
  width: 3px;
  margin-left: -1px; }

div.dt-autofill-list {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  margin-left: -250px;
  background-color: white;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 5px #555;
  box-shadow: 0 0 5px #555;
  border: 2px solid #444;
  z-index: 11;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1.5em 2em; }

div.dt-autofill-list ul {
  display: table;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%; }

div.dt-autofill-list ul li {
  display: table-row; }

div.dt-autofill-list ul li:last-child div.dt-autofill-question, div.dt-autofill-list ul li:last-child div.dt-autofill-button {
  border-bottom: none; }

div.dt-autofill-list ul li:hover {
  background-color: #f6f6f6; }

div.dt-autofill-list div.dt-autofill-question {
  display: table-cell;
  padding: 0.5em 0;
  border-bottom: 1px solid #ccc; }

div.dt-autofill-list div.dt-autofill-question input[type=number] {
  padding: 6px;
  width: 30px;
  margin: -2px 0; }

div.dt-autofill-list div.dt-autofill-button {
  display: table-cell;
  padding: 0.5em 0;
  border-bottom: 1px solid #ccc; }

div.dt-autofill-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  background: radial-gradient(ellipse farthest-corner at center, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  z-index: 10; }

div.dt-autofill-list div.dt-autofill-question input[type=number] {
  padding: 6px;
  width: 60px;
  margin: -2px 0; }

table.DTCR_clonedTable.dataTable {
  position: absolute !important;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 202; }

div.DTCR_pointer {
  width: 1px;
  background-color: #0275d8;
  z-index: 201; }

table.DTFC_Cloned tr {
  background-color: white;
  margin-bottom: 0; }

div.DTFC_LeftHeadWrapper table, div.DTFC_RightHeadWrapper table {
  border-bottom: none !important;
  margin-bottom: 0 !important;
  background-color: white; }

div.DTFC_LeftBodyWrapper table, div.DTFC_RightBodyWrapper table {
  border-top: none;
  margin: 0 !important;
  z-index: 2; }

div.DTFC_LeftBodyWrapper table thead .sorting:before, div.DTFC_LeftBodyWrapper table thead .sorting:after, div.DTFC_LeftBodyWrapper table thead .sorting_asc:before, div.DTFC_LeftBodyWrapper table thead .sorting_asc:after, div.DTFC_LeftBodyWrapper table thead .sorting_desc:before, div.DTFC_LeftBodyWrapper table thead .sorting_desc:after, div.DTFC_LeftBodyWrapper table thead .sorting:before, div.DTFC_LeftBodyWrapper table thead .sorting:after, div.DTFC_LeftBodyWrapper table thead .sorting_asc:before, div.DTFC_LeftBodyWrapper table thead .sorting_asc:after, div.DTFC_LeftBodyWrapper table thead .sorting_desc:before, div.DTFC_LeftBodyWrapper table thead .sorting_desc:after, div.DTFC_RightBodyWrapper table thead .sorting:before, div.DTFC_RightBodyWrapper table thead .sorting:after, div.DTFC_RightBodyWrapper table thead .sorting_asc:before, div.DTFC_RightBodyWrapper table thead .sorting_asc:after, div.DTFC_RightBodyWrapper table thead .sorting_desc:before, div.DTFC_RightBodyWrapper table thead .sorting_desc:after, div.DTFC_RightBodyWrapper table thead .sorting:before, div.DTFC_RightBodyWrapper table thead .sorting:after, div.DTFC_RightBodyWrapper table thead .sorting_asc:before, div.DTFC_RightBodyWrapper table thead .sorting_asc:after, div.DTFC_RightBodyWrapper table thead .sorting_desc:before, div.DTFC_RightBodyWrapper table thead .sorting_desc:after {
  display: none; }

div.DTFC_LeftBodyWrapper table tbody tr:first-child th, div.DTFC_LeftBodyWrapper table tbody tr:first-child td, div.DTFC_RightBodyWrapper table tbody tr:first-child th, div.DTFC_RightBodyWrapper table tbody tr:first-child td {
  border-top: none; }

div.DTFC_LeftFootWrapper table, div.DTFC_RightFootWrapper table {
  border-top: none;
  margin-top: 0 !important;
  background-color: white; }

div.DTFC_Blocker {
  background-color: white; }

table.dataTable.table-striped.DTFC_Cloned tbody {
  background-color: white; }

table.dataTable.fixedHeader-floating, table.dataTable.fixedHeader-locked {
  background-color: white;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

table.dataTable.fixedHeader-floating {
  position: fixed !important; }

table.dataTable.fixedHeader-locked {
  position: absolute !important; }

@media print {
  table.fixedHeader-floating {
    display: none; } }

table.dataTable tbody th.focus, table.dataTable tbody td.focus {
  -webkit-box-shadow: inset 0 0 1px 2px #0275d8;
  box-shadow: inset 0 0 1px 2px #0275d8; }

div.dtk-focus-alt table.dataTable tbody th.focus, div.dtk-focus-alt table.dataTable tbody td.focus {
  -webkit-box-shadow: inset 0 0 1px 2px #ff8b33;
  box-shadow: inset 0 0 1px 2px #ff8b33; }

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child, table.dataTable.dtr-inline.collapsed > tbody > tr > th.child, table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
  cursor: default !important; }

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child:before, table.dataTable.dtr-inline.collapsed > tbody > tr > th.child:before, table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty:before {
  display: none !important; }

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child, table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child {
  position: relative;
  padding-left: 30px;
  cursor: pointer; }

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
  top: 12px;
  left: 4px;
  height: 14px;
  width: 14px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 14px;
  -webkit-box-shadow: 0 0 3px #444;
  box-shadow: 0 0 3px #444;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: 'Courier New', Courier, monospace;
  line-height: 14px;
  content: '+';
  background-color: #0275d8; }

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  content: '-';
  background-color: #d33333; }

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child, table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child {
  padding-left: 27px; }

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child:before, table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child:before {
  top: 5px;
  left: 4px;
  height: 14px;
  width: 14px;
  border-radius: 14px;
  line-height: 14px;
  text-indent: 3px; }

table.dataTable.dtr-column > tbody > tr > td.control, table.dataTable.dtr-column > tbody > tr > th.control {
  position: relative;
  cursor: pointer; }

table.dataTable.dtr-column > tbody > tr > td.control:before, table.dataTable.dtr-column > tbody > tr > th.control:before {
  top: 50%;
  left: 50%;
  height: 16px;
  width: 16px;
  margin-top: -10px;
  margin-left: -10px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 14px;
  -webkit-box-shadow: 0 0 3px #444;
  box-shadow: 0 0 3px #444;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: 'Courier New', Courier, monospace;
  line-height: 14px;
  content: '+';
  background-color: #0275d8; }

table.dataTable.dtr-column > tbody > tr.parent td.control:before, table.dataTable.dtr-column > tbody > tr.parent th.control:before {
  content: '-';
  background-color: #d33333; }

table.dataTable > tbody > tr.child {
  padding: 0.5em 1em; }

table.dataTable > tbody > tr.child:hover {
  background: transparent !important; }

table.dataTable > tbody > tr.child ul.dtr-details {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0; }

table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom: 1px solid #efefef;
  padding: 0.5em 0; }

table.dataTable > tbody > tr.child ul.dtr-details > li:first-child {
  padding-top: 0; }

table.dataTable > tbody > tr.child ul.dtr-details > li:last-child {
  border-bottom: none; }

table.dataTable > tbody > tr.child span.dtr-title {
  display: inline-block;
  min-width: 75px;
  font-weight: bold; }

div.dtr-modal {
  position: fixed;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 10em 1em; }

div.dtr-modal div.dtr-modal-display {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  z-index: 102;
  overflow: auto;
  background-color: #f5f5f7;
  border: 1px solid black;
  border-radius: 0.5em;
  -webkit-box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6); }

div.dtr-modal div.dtr-modal-content {
  position: relative;
  padding: 1em; }

div.dtr-modal div.dtr-modal-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 22px;
  height: 22px;
  border: 1px solid #eaeaea;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  z-index: 12; }

div.dtr-modal div.dtr-modal-close:hover {
  background-color: #eaeaea; }

div.dtr-modal div.dtr-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.6); }

@media screen and (max-width: 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%; } }

div.dtr-bs-modal table.table tr:first-child td {
  border-top: none; }

table.dataTable tr.dtrg-group td {
  background-color: #e0e0e0; }

table.dataTable tr.dtrg-group.dtrg-level-0 td {
  font-weight: bold; }

table.dataTable tr.dtrg-group.dtrg-level-1 td, table.dataTable tr.dtrg-group.dtrg-level-2 td {
  background-color: #f0f0f0;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: 2em;
  font-size: 0.9em; }

table.dataTable tr.dtrg-group.dtrg-level-2 td {
  background-color: #f3f3f3; }

table.dt-rowReorder-float {
  position: absolute !important;
  opacity: 0.8;
  table-layout: fixed;
  outline: 2px solid #0275d8;
  outline-offset: -2px;
  z-index: 2001; }

tr.dt-rowReorder-moving {
  outline: 2px solid #888888;
  outline-offset: -2px; }

body.dt-rowReorder-noOverflow {
  overflow-x: hidden; }

table.dataTable td.reorder {
  text-align: center;
  cursor: move; }

div.dts {
  display: block !important; }

div.dts tbody th, div.dts tbody td {
  white-space: nowrap; }

div.dts div.dts_loading {
  z-index: 1; }

div.dts div.dts_label {
  position: absolute;
  right: 10px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  -webkit-box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  text-align: right;
  border-radius: 3px;
  padding: 0.4em;
  z-index: 2;
  display: none; }

div.dts div.dataTables_scrollBody {
  background: repeating-linear-gradient(45deg, #edeeff, #edeeff 10px, #fff 10px, #fff 20px); }

div.dts div.dataTables_scrollBody table {
  z-index: 2; }

div.dts div.dataTables_paginate, div.dts div.dataTables_length {
  display: none; }

div.DTS div.dataTables_scrollBody table {
  background-color: white; }

table.dataTable tbody > tr.selected, table.dataTable tbody > tr > .selected {
  background-color: #0275d8; }

table.dataTable.stripe tbody > tr.odd.selected, table.dataTable.stripe tbody > tr.odd > .selected, table.dataTable.display tbody > tr.odd.selected, table.dataTable.display tbody > tr.odd > .selected {
  background-color: #0172d2; }

table.dataTable.hover tbody > tr.selected:hover, table.dataTable.hover tbody > tr > .selected:hover, table.dataTable.display tbody > tr.selected:hover, table.dataTable.display tbody > tr > .selected:hover {
  background-color: #0170d0; }

table.dataTable.order-column tbody > tr.selected > .sorting_1, table.dataTable.order-column tbody > tr.selected > .sorting_2, table.dataTable.order-column tbody > tr.selected > .sorting_3, table.dataTable.order-column tbody > tr > .selected, table.dataTable.display tbody > tr.selected > .sorting_1, table.dataTable.display tbody > tr.selected > .sorting_2, table.dataTable.display tbody > tr.selected > .sorting_3, table.dataTable.display tbody > tr > .selected {
  background-color: #0172d3; }

table.dataTable.display tbody > tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_1 {
  background-color: #016ecc; }

table.dataTable.display tbody > tr.odd.selected > .sorting_2, table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_2 {
  background-color: #016fcd; }

table.dataTable.display tbody > tr.odd.selected > .sorting_3, table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_3 {
  background-color: #0170cf; }

table.dataTable.display tbody > tr.even.selected > .sorting_1, table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_1 {
  background-color: #0172d3; }

table.dataTable.display tbody > tr.even.selected > .sorting_2, table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_2 {
  background-color: #0173d5; }

table.dataTable.display tbody > tr.even.selected > .sorting_3, table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_3 {
  background-color: #0174d7; }

table.dataTable.display tbody > tr.odd > .selected, table.dataTable.order-column.stripe tbody > tr.odd > .selected {
  background-color: #016ecc; }

table.dataTable.display tbody > tr.even > .selected, table.dataTable.order-column.stripe tbody > tr.even > .selected {
  background-color: #0172d3; }

table.dataTable.display tbody > tr.selected:hover > .sorting_1, table.dataTable.order-column.hover tbody > tr.selected:hover > .sorting_1 {
  background-color: #016bc6; }

table.dataTable.display tbody > tr.selected:hover > .sorting_2, table.dataTable.order-column.hover tbody > tr.selected:hover > .sorting_2 {
  background-color: #016cc7; }

table.dataTable.display tbody > tr.selected:hover > .sorting_3, table.dataTable.order-column.hover tbody > tr.selected:hover > .sorting_3 {
  background-color: #016dca; }

table.dataTable.display tbody > tr:hover > .selected, table.dataTable.display tbody > tr > .selected:hover, table.dataTable.order-column.hover tbody > tr:hover > .selected, table.dataTable.order-column.hover tbody > tr > .selected:hover {
  background-color: #016bc6; }

table.dataTable tbody td.select-checkbox, table.dataTable tbody th.select-checkbox {
  position: relative; }

table.dataTable tbody td.select-checkbox:before, table.dataTable tbody td.select-checkbox:after, table.dataTable tbody th.select-checkbox:before, table.dataTable tbody th.select-checkbox:after {
  display: block;
  position: absolute;
  top: 1.2em;
  left: 50%;
  width: 12px;
  height: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

table.dataTable tbody td.select-checkbox:before, table.dataTable tbody th.select-checkbox:before {
  content: ' ';
  margin-top: -6px;
  margin-left: -6px;
  border: 1px solid black;
  border-radius: 3px; }

table.dataTable tr.selected td.select-checkbox:after, table.dataTable tr.selected th.select-checkbox:after {
  content: '\2714';
  margin-top: -11px;
  margin-left: -4px;
  text-align: center;
  text-shadow: 1px 1px #B0BED9, -1px -1px #B0BED9, 1px -1px #B0BED9, -1px 1px #B0BED9; }

div.dataTables_wrapper span.select-info, div.dataTables_wrapper span.select-item {
  margin-left: 0.5em; }

@media screen and (max-width: 640px) {
  div.dataTables_wrapper span.select-info, div.dataTables_wrapper span.select-item {
    margin-left: 0;
    display: block; } }

table.dataTable tbody tr.selected, table.dataTable tbody th.selected, table.dataTable tbody td.selected {
  color: white; }

table.dataTable tbody tr.selected a, table.dataTable tbody th.selected a, table.dataTable tbody td.selected a {
  color: #a2d4ed; }
